export const BuyMeACoffee = () => import('../../app/components/BuyMeACoffee.vue' /* webpackChunkName: "components/buy-me-a-coffee" */).then(c => wrapFunctional(c.default || c))
export const CalendarView = () => import('../../app/components/CalendarView.vue' /* webpackChunkName: "components/calendar-view" */).then(c => wrapFunctional(c.default || c))
export const CookieFooterWrapper = () => import('../../app/components/CookieFooterWrapper.vue' /* webpackChunkName: "components/cookie-footer-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FooterText = () => import('../../app/components/FooterText.vue' /* webpackChunkName: "components/footer-text" */).then(c => wrapFunctional(c.default || c))
export const GoogleAdsense = () => import('../../app/components/GoogleAdsense.vue' /* webpackChunkName: "components/google-adsense" */).then(c => wrapFunctional(c.default || c))
export const HistoryTags = () => import('../../app/components/HistoryTags.vue' /* webpackChunkName: "components/history-tags" */).then(c => wrapFunctional(c.default || c))
export const LoadedMarkdown = () => import('../../app/components/LoadedMarkdown.vue' /* webpackChunkName: "components/loaded-markdown" */).then(c => wrapFunctional(c.default || c))
export const ModeChange = () => import('../../app/components/ModeChange.vue' /* webpackChunkName: "components/mode-change" */).then(c => wrapFunctional(c.default || c))
export const NavText = () => import('../../app/components/NavText.vue' /* webpackChunkName: "components/nav-text" */).then(c => wrapFunctional(c.default || c))
export const NoticeBar = () => import('../../app/components/NoticeBar.vue' /* webpackChunkName: "components/notice-bar" */).then(c => wrapFunctional(c.default || c))
export const NotifyAlert = () => import('../../app/components/NotifyAlert.vue' /* webpackChunkName: "components/notify-alert" */).then(c => wrapFunctional(c.default || c))
export const PostCard = () => import('../../app/components/PostCard.vue' /* webpackChunkName: "components/post-card" */).then(c => wrapFunctional(c.default || c))
export const SocialMenu = () => import('../../app/components/SocialMenu.vue' /* webpackChunkName: "components/social-menu" */).then(c => wrapFunctional(c.default || c))
export const StripeLink = () => import('../../app/components/StripeLink.vue' /* webpackChunkName: "components/stripe-link" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
